<template>
  <div class="loading-screen">
    <loading-spinner></loading-spinner>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.loading-screen {
  width: 100vw;
  height: 100vh;
}
</style>
